<template>
  <b-card
    title="Paghiper"
    class="top-level p-1"
  >
    <b-row align-v="center">
      <b-col md="4">
        <b-form-group>
          <label>Token</label>
          <b-form-input
            id="name"
            v-model="PAGHIPER_TOKEN.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>Chave API</label>
          <b-form-input
            id="name"
            v-model="PAGHIPER_APIKEY.value"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-button
          class="float-right"
          variant="success"
          @click.stop="saveKeys()"
        >
          Gravar
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    attributeId: {
      type: String,
      required: false,
      default: '-1',
    },
  },
  data() {
    return {
      PAGHIPER_TOKEN: {
        value: '',
      },
      PAGHIPER_APIKEY: {
        value: '',
      },
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.$store.dispatch('configurations/fetchConfiguration', 'PAGHIPER_TOKEN').then(result => {
      this.PAGHIPER_TOKEN = result
    })
    this.$store.dispatch('configurations/fetchConfiguration', 'PAGHIPER_APIKEY').then(result => {
      this.PAGHIPER_APIKEY = result
    })
  },
  methods: {
    saveKeys() {
      this.$store.dispatch('configurations/updateConfiguration', this.PAGHIPER_TOKEN).then(result => {
        this.PAGHIPER_TOKEN = result
        this.$store.dispatch('configurations/updateConfiguration', this.PAGHIPER_APIKEY).then(result => {
          this.PAGHIPER_APIKEY = result
        })
      }).then(() => {
        this.$bvToast.toast('Chaves gravadas com sucesso!', {
          title: 'Sucesso',
          variant: 'success',
          solid: true,
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Paghiper',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Não conseguimos gravar as chaves. Verificar que todos os dados estão visíveis sem ***.',
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.top-level {
  background: lightgrey;
}
</style>
